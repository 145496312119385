<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__header">
        <div class="left">
          <span> Filtros </span>
        </div>

        <div class="right">
          <div>
            <z-btn
              small
              rounded
              text="voltar"
              icon="$keyboard_return_rounded"
              @click="$router.go(-1)"
            />
          </div>
        </div>
      </div>
      <div class="wrapper__card__body">
        <div class="wrapper__card__body__content">
          <div class="wrapper__card__body__content__left">
            <z-input
              v-model="choosenName"
              label="Nome da configuração"
              type="text"
              :hideDetails="true"
              class="mb-3"
            />
            <z-select
              label="Selecione uma conexão"
              v-model="choosenConnection"
              :items="dbConfigList"
              itemText="name"
              placeholder="Pesquisar"
              type="text"
              :hideDetails="true"
              returnObject
            />

            <div
              v-if="dbConfigTransformedList.length"
              class="wrapper__card__body__content__left__columns"
            >
              <span class="title__items">Conexões</span>

              <div class="wrapper__card__body__content__left__columns__list">
                <ColumnItem
                  v-for="(item, i) in dbConfigTransformedList"
                  :key="i"
                  :info="item"
                  class="item"
                  @startDraggin="startDrag"
                />
              </div>
            </div>
          </div>

          <v-divider vertical class="hide"></v-divider>

          <div class="wrapper__card__body__content__right">
            <TreeList
              :info="elementsData"
              :send-data-prop="getDatasInfo"
              @formContent="submitFormQuery"
            />
          </div>
        </div>
      </div>

      <div class="wrapper__card--actions">
        <z-btn
          small
          rounded
          primary
          text="Adicionar"
          :is-loading="loadingAddAudience"
          @click="addData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { columnsMock, treeElementsMock, elementsGroups } from "./mock";
import ApiClient from "@/services/ApiClient";
import TreeList from "./components/blockList.vue";
import ColumnItem from "./components/columnItem.vue";

export default {
  components: {
    ColumnItem,
    TreeList,
  },
  data() {
    return {
      choosenConnection: null,
      loadingAddAudience: false,
      choosenName: "",
      isMountedData: false,
      getDatasInfo: false,
      isMountData: false,
      http: new ApiClient(),
      columnsMock,
      treeElementsMock,
      elementsGroups,
      elementsData: {
        groups: [
          {
            auxId: "",
            conditions: [],
            relation: null,
          },
        ],
      },
      query: null,
      dragging: true,
      test: [],
      dbConfigList: [],
      dbConfigResponseList: [],
      dbConfigTransformedList: [],
    };
  },
  watch: {
    choosenConnection(nv) {
      if (nv) this.getConnectionsList();
    },
  },
  created() {
    this.getPrimaryDataConfigList();
    this.elementsData.groups[0].auxId = this.generateRandomId();
  },
  methods: {
    addData() {
      this.getDatasInfo = true;

      setTimeout(() => {
        this.getDatasInfo = false;
      }, 500);
    },
    resetDataGroups() {
      this.elementsData.groups = [
        {
          auxId: "",
          conditions: [],
          relation: null,
        },
      ];
    },
    async submitFormQuery(form) {
      try {
        this.loadingAddAudience = true;
        const OBJ = {};
        OBJ.databaseConfigId = this.choosenConnection.id;
        OBJ.name = this.choosenName;
        OBJ.fields = form.groups;

        const response = await this.http.post("audienceFilter/addAudience", OBJ);

        if (response.toLowerCase() == "ok") {
          this.$toast.success("Configuração realizada com sucesso");
          this.loadingAddAudience = false;
          this.resetDataGroups();
          this.$router.push({ name: "audienceIndexPage" });
        }
      } catch (error) {
        this.$toast.error("Erro ao salvar dados");
        this.loadingAddAudience = false;
      }
    },
    async getConnectionsList() {
      try {
        this.dbConfigResponseList = [];
        const response = await this.http.get(
          `dbConfiguration/related/${this.choosenConnection.id}`
        );

        this.dbConfigResponseList.push({
          ...response.primaryConfig,
          configurationType: "primaryData",
        });

        if (response?.additionalData.length) {
          for (const i of response.additionalData) {
            this.dbConfigResponseList.push({ ...i, configurationType: "additionalData" });
          }
        }
        if (response?.conversionData.length) {
          for (const i of response.conversionData) {
            this.dbConfigResponseList.push({ ...i, configurationType: "conversionData" });
          }
        }
        this.transformResponseToRedableForm();
      } catch (error) {
        this.$toast.error("Erro ao buscar configurações");
      }
    },

    async getPrimaryDataConfigList() {
      try {
        const req = await this.http.get("dbConfiguration/list?status=updated&sort=created&order=DESC");
        this.dbConfigList = req;
      } catch (error) {
        this.$toast.error("Erro ao buscar lista de configurações");
      }
    },

    startDrag(evt, item) {
      const id = this.generateRandomId();
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      item.auxId = id;
      evt.dataTransfer.setData("itemID", id);
    },

    transformResponseToRedableForm() {
      this.isMountData = true;
      this.dbConfigTransformedList = [];

      for (const column of this.dbConfigResponseList) {
        let obj = {};

        obj.name = column.name;
        obj.children = [];

        const PARSED_OBJECT = JSON.parse(column.fields);

        for (const [key, value] of Object.entries(PARSED_OBJECT)) {
          obj.children.push({
            name: key,
            condiction: "",
            resp: "",
            auxId: "",
            type: value.type,
            configurationType: column?.configurationType,
            configurationId: column?.id,
          });
        }
        this.dbConfigTransformedList.push(obj);
      }
      this.isMountData = false;
    },

    generateRandomId() {
      const PART_1 = Math.floor(Math.random() * 245);
      const PART_2 = Date.now();
      return `${PART_1}${PART_2}`;
    },
  },
  // provide: {
  //   // columnsMock,
  // },
  provide() {
    return {
      dbConfigTransformedList: computed(() => this.dbConfigTransformedList),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;
    & > div {
      // border: 1px solid red;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: 1.5rem;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      margin-bottom: 1.5rem;
      &__content {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: $z-s-1;

        & > div {
          padding: 0.5rem 0.8rem;
          height: 100%;
        }
        &__left {
          flex: 1 1 200px;
          &__columns {
            margin-top: 0.5rem;

            .title__items {
              font-weight: 700;
              font-size: 0.95em;
              line-height: 13px;
              letter-spacing: 0.15px;
              text-transform: uppercase;
            }
            &__list {
              padding: 0.3rem $z-s-2;
              max-height: 50vh;
              overflow-y: auto;
              @include trackScrollBar;
              .item {
                &:not(:last-child) {
                  margin-bottom: 0.3rem;
                }
              }
            }
          }
        }
        &__right {
          flex: 3 1 400px;
          max-height: 70vh;
          overflow-y: auto;
          @include trackScrollBar;

          display: flex;
          flex-direction: column;
          gap: 0.7rem;

          .content {
            min-height: 28vh;
            max-height: 28vh;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            padding: 0.8rem;
            border-radius: 15px;

            overflow-y: auto;
            @include trackScrollBar;
            & > div {
              margin-bottom: $z-s-1;
            }
            &__line {
              &__item {
                display: flex;
                align-items: center;
                gap: 0.3rem;
              }
            }
            &__condiction {
            }
          }
        }
        .no-content {
          min-height: 60vh;
        }

        @media (max-width: 737px) {
          .hide {
            display: none;
          }
        }
      }
    }

    &--actions {
      margin-top: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
